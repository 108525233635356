import React from "react";

import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Page not found" />
    <div className="flex flex-col justify-center items-center max-w-[90%] lg:max-w-[70vw] mx-auto my-0 small-height-404 md:big-height-404">
      <StaticImage
        src="../images/404.png"
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="404"
        placeholder="blurred"
        objectFit="contain"
      />
      <p className="font-fantasy">Whooops!! Page not found.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
